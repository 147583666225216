<template>
  <div class="users-form">
    <v-dialog v-model="show" persistent max-width="600" content-class="ma-0 mx-2">
      <v-card>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <v-form>
            <v-card-title class="mb-4">{{ title }}</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="mb-3">
                  <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules="required"
                  >
                    <v-text-field
                        name="name"
                        v-model="form.name"
                        label="Nome"
                        required
                        :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="mb-3">
                  <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                  >
                    <v-text-field
                        v-model="form.email"
                        name="email"
                        label="E-mail"
                        type="email"
                        required
                        :error-messages="errors"
                        @blur="$refs.observer.validate()"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="mb-3">
                  <reset-password v-model="form.password" :is-new="!isEdit" :show="show"/>
                </v-col>
                <v-col v-if="!isUserYourSelf(data.email) && isEdit" cols="1" class="mb-3">
                  <v-switch v-model="form.active" label="Ativo" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-4">
              <v-spacer></v-spacer>
              <v-btn small class="mr-lg-2" @click="_onCancel">
                Cancelar
              </v-btn>
              <v-btn small :disabled="invalid" color="primary" @click="_onSave">
                <v-icon small class="mr-1">mdi-content-save</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import ResetPassword from "@/components/admin/users/reset-password";

const initForm = {
  name: '',
  email: '',
  password: '',
  active: true
}

extend("email", {
  ...email,
  message: "e-mail inválido",
});

extend("required", {
  ...required,
  message: 'obrigatório',
});

setInteractionMode("eager");

export default {
  components: {
    ResetPassword,
    ValidationObserver,
    ValidationProvider
  },
  props: ['data','title','onSave','onCancel','isEdit'],
  data: () => ({
    show: false,
    showPassword: false,
    form: {...initForm},
  }),
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    users() {
      return this.$store.state.users;
    },
    isUserYourSelf() {
      return email => this.auth.data.email === email
    }
  },
  methods: {
    _onSave() {
      this.form.password_reset = this.users.passwordReset
      this.onSave(this.form)
      this.resetFormAndClose()
    },
    _onCancel() {
      this.resetFormAndClose()
      this.onCancel && this.onCancel()
    },
    resetFormAndClose() {
      this.form = {...initForm}
      this.show = false;
      this.$refs.observer.reset();
    },
  },
  watch: {
    show(nextValue) {
      if(nextValue) {
        this.form = Object.assign({}, this.data)
      }
    }
  }
}
</script>

<style scoped>

</style>
