<template>
  <div class="users-form">
    <v-dialog v-model="show" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4">{{ title }}</v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn class="mr-lg-2" @click="_onNo">
            Não
          </v-btn>
          <v-btn color="error" @click="_onYes">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>


export default {
  props: ['title','text','onYes','onNo','data'],
  data: () => ({
    show: false,
  }),
  methods: {
    _onYes() {
      this.show = false;
      this.onYes(this.data)
    },
    _onNo() {
      this.show = false;
      this.onNo && this.onNo()
    },
  },
}
</script>

<style scoped>

</style>
