var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"comparative-brief",attrs:{"title":"Usuários"}},[_c('snackbar-message',{attrs:{"message":_vm.snackbarMessage,"status":_vm.snackbarStatus},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}}),_c('v-card',{staticClass:"mt-1 mb-1 overflow-y-auto",attrs:{"height":"calc(100vh - 100px)"}},[_c('v-card-title',[_c('v-layout',{attrs:{"justify-end":""}},[_c('users-form',{attrs:{"title":"Novo","data":{},"on-save":_vm.onSave},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"success","dark":""}},'v-btn',attr,false),on),[_c('span',{staticClass:"ml-2",staticStyle:{"position":"relative"}},[_c('v-icon',{staticStyle:{"position":"absolute","left":"-10px"},attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account")])],1),_c('span',[_vm._v("Novo")])])]}}])})],1)],1),_c('v-divider'),_c('v-data-table',_vm._b({attrs:{"height":"calc(100vh - 240px)","items":_vm.users.data,"loading":_vm.users.status === 1},scopedSlots:_vm._u([{key:"item.num",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.users.data.indexOf(item) + 1)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.name)))])]}},{key:"item.user_profile.profile.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.user_profile.profile.name)))])]}},{key:"item.password_reset",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":!item.password_reset ? 'Não' : 'Sim'}},[_c('v-icon',{attrs:{"color":!item.password_reset ? 'green lighten-1' : 'orange lighten-2'}},[_vm._v(_vm._s(!item.password_reset ? 'mdi-lock' : 'mdi-key'))])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.active ? 'ativo' : 'desativado'}},[_c('v-icon',{attrs:{"color":item.active ? 'green lighten-1' : 'red accent-1'}},[_vm._v(_vm._s(item.active ? 'mdi-check' : 'mdi-cancel'))])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleDateString()))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updated_at).toLocaleDateString()))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',[_c('users-form',{attrs:{"title":"Editar","data":item || {},"is-edit":true,"on-save":_vm.onUpdate,"on-cancel":_vm.onCancel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"title":"editar"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('yes-no-dialog',{attrs:{"data":item,"title":"Excluir?","text":"Deseja realmente excluir?","on-yes":_vm.onDelete},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"title":"excluir"}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]}}])},'v-data-table',_vm.dataTableProps,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }