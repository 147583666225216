<template>
  <page class="comparative-brief" title="Usuários">
    <snackbar-message v-model="showSnackbar" :message="snackbarMessage" :status="snackbarStatus" />
    <v-card height="calc(100vh - 100px)" class="mt-1 mb-1 overflow-y-auto">
      <v-card-title>
        <v-layout justify-end>
          <users-form title="Novo" :data="{}" :on-save="onSave">
            <template #default="{on, attr}">
              <v-btn small color="success" dark v-on="on" v-bind="attr">
                <span class="ml-2" style="position: relative">
                <v-icon small style="position: absolute; left: -10px;">mdi-plus</v-icon>
                <v-icon small class="mr-1">mdi-account</v-icon>
                </span>
                <span>Novo</span>
              </v-btn>
            </template>
          </users-form>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
          height="calc(100vh - 240px)"
          :items="users.data"
          :loading="users.status === 1"
          v-bind="dataTableProps"
      >
        <template #item.num="{item}">
          <span>
            {{ users.data.indexOf(item) + 1 }}
          </span>
        </template>
        <template #item.name="{item}">
          <span>{{ item.name | capitalize }}</span>
        </template>
        <template #item.user_profile.profile.name="{item}">
          <span>{{ item.user_profile.profile.name | capitalize }}</span>
        </template>
        <template #item.password_reset="{item}">
          <span :title="!item.password_reset ? 'Não' : 'Sim'">
               <v-icon :color="!item.password_reset ? 'green lighten-1' : 'orange lighten-2'">{{ !item.password_reset ? 'mdi-lock' : 'mdi-key' }}</v-icon>
          </span>
        </template>
        <template #item.user_profile.profile.name="{item}">
          <span>{{ item.user_profile.profile.name | capitalize }}</span>
        </template>
        <template #item.active="{item}">
          <span :title="item.active ? 'ativo' : 'desativado'">
            <v-icon :color="item.active ? 'green lighten-1' : 'red accent-1'">{{ item.active ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
          </span>
        </template>
        <template #item.created_at="{item}">
          <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
        </template>
        <template #item.updated_at="{item}">
          <span>{{ new Date(item.updated_at).toLocaleDateString() }}</span>
        </template>
        <template #item.actions="{item}">
          <v-layout>
            <users-form title="Editar" :data="item || {}" :is-edit="true" :on-save="onUpdate" :on-cancel="onCancel">
              <template #default="{on, attrs}">
                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">
                  <v-icon title="editar">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </users-form>
            <yes-no-dialog :data="item" title="Excluir?" text="Deseja realmente excluir?" :on-yes="onDelete">
              <template #default="{on, attrs}">
                <v-btn icon color="error" class="mr-2" v-on="on" v-bind="attrs">
                  <v-icon title="excluir">mdi-delete</v-icon>
                </v-btn>
              </template>
            </yes-no-dialog>
          </v-layout>
        </template>
      </v-data-table>
    </v-card>
  </page>
</template>

<script>
import Page from "@/components/containers/page";
import UsersForm from "@/views/admin/users/form";
import SnackbarMessage from "@/components/snackbars/snackbar-message";
import YesNoDialog from "@/components/dialogs/yes-no-dialog";

export default {
  components: {YesNoDialog, SnackbarMessage, UsersForm, Page},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(vm.$store.getters["auth/isAuthenticated"] && vm.$store.getters["auth/isAdmin"]) {
        return next()
      } else {
        vm.$router.push({name: 'Login'})
      }
    })
  },
  data: () => ({
    showSnackbar: false,
    snackbarStatus: 'error',
    snackbarMessage: '',
    dataTableProps: {
      headers: [
        {text: 'Nº', value: 'num'},
        // {text: 'Conta', value: 'user_profile.profile.name'},
        {text: 'Nome', value: 'name'},
        {text: 'E-mail', value: 'email'},
        {text: 'Ativo', value: 'active'},
        {text: 'Trocar Senha', value: 'password_reset'},
        {text: 'Criado', value: 'created_at'},
        {text: 'Atualizado', value: 'updated_at'},
        {text: 'Ações', value: 'actions'},
      ],
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: 'linhas por página',
        itemsPerPageOptions: [7, 14, 28,],
        itemsPerPageAllText: 'Todos',
        pageText: '{0}-{1} de {2}'
      },
      loadingText: 'carregando aguarde...',
      noDataText: 'nenhum registro encontrado'
    },
  }),
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  mounted() {
    this.$store.dispatch('users/getAll')
  },
  methods: {
    onSave(data) {
      this.$store.dispatch('users/create', data)
          .then(() => this.$store.dispatch('users/getAll'))
          .catch(err => {
            if([401,403].includes(err.status)) {
              setTimeout(() => {
                this.$router.push({name: 'Login'})
                this.$store.dispatch('auth/revokeAthentication')
              }, 2000)
            }
          })
    },
    onUpdate(data) {
      this.$store.dispatch('users/update', data)
          .then(() => this.$store.dispatch('users/getAll'))
          .catch(err => {
            if([401,403].includes(err.status)) {
              setTimeout(() => {
                this.$router.push({name: 'Login'})
                this.$store.dispatch('auth/revokeAthentication')
              }, 2000)
            }
          })
    },
    onCancel() {
      this.$store.commit('users/setPasswordReset', false)
    },
    onDelete(item) {
      this.$store.dispatch('users/delete', item.id)
          .then(() => this.$store.dispatch('users/getAll'))
          .catch(err => {
            if([401,403].includes(err.status)) {
              setTimeout(() => {
                this.$router.push({name: 'Login'})
                this.$store.dispatch('auth/revokeAthentication')
              }, 2000)
            }
          })
    }
  },
  watch: {
    'users.status'(next,prev) {
      if (prev === 1 && next === -1 && ['update','create','delete'].includes(this.users.operation)) {
        this.snackbarMessage = this.users.message
        this.snackbarStatus = 'error'
        this.showSnackbar = true
      } else if(prev === 1 && next === 0 && ['update','create','delete'].includes(this.users.operation)) {
        this.snackbarMessage = this.users.message
        this.snackbarStatus = 'success'
        this.showSnackbar = true
      }
    }
  }
}
</script>

<style scoped>

</style>
