var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-form"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600","content-class":"ma-0 mx-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,null,{on: on, attrs: attrs})]}}],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":"Nome","required":"","error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","label":"E-mail","type":"email","required":"","error-messages":errors},on:{"blur":function($event){return _vm.$refs.observer.validate()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('reset-password',{attrs:{"is-new":!_vm.isEdit,"show":_vm.show},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),(!_vm.isUserYourSelf(_vm.data.email) && _vm.isEdit)?_c('v-col',{staticClass:"mb-3",attrs:{"cols":"1"}},[_c('v-switch',{attrs:{"label":"Ativo"},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-lg-2",attrs:{"small":""},on:{"click":_vm._onCancel}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"small":"","disabled":invalid,"color":"primary"},on:{"click":_vm._onSave}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }