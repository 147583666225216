<template>
  <v-snackbar :value="show" top :color="color" @input="value => $emit('input', value)">
    <v-layout align-center>
      <v-icon class="mr-1" v-text="'mdi-'+icon" />
      {{ message }}
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  props: ['show','status','message'],
  model: {
    prop: 'show',
    event: 'input'
  },
  data: () => ({
    color: '',
    icon: ''
  }),
  updated() {
    if(this.status === 'success') {
      this.color = "success"
      this.icon = "check-circle"
    } else if(this.status === 'error') {
      this.color = "error"
      this.icon = "close-circle"
    }
  }
}
</script>

<style scoped>

</style>
