<template>
  <div class="reset-password">
    <v-btn v-if="!showNewPassword" @click="onGenerateNewPassword" color="primary" x-small>
      <v-icon x-small class="mr-1">mdi-key</v-icon>
      criar nova senha
    </v-btn>
    <v-text-field
        v-if="showNewPassword"
        :value="newPassword"
        label="Senha"
        readonly filled
        hide-details
        persistent-hint
    >
      <template #append>
        <v-btn title="clique para copiar" icon @click="onCopyToClipboard">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <div v-if="showNewPassword" class="caption grey--text text--darken-2 mx-3 mt-1">
      copie a senha e após clicar em 'salvar' envie a senha para o usuário, quando ele fazer o login com essa senha, ele será direcionado para criar uma nova senha pessoal.
    </div>
    <v-layout class="mt-3">
      <v-btn v-if="showNewPassword" @click="onGenerateNewPassword" x-small class="mr-3" color="primary">
        <v-icon x-small class="mr-1">mdi-sync</v-icon>
        gerar outra senha
      </v-btn>
      <v-btn v-if="showNewPassword && !isNew" @click="onCancel" x-small>
        cancelar
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ['password','isNew', 'show'],
  model: {
    prop: 'password',
    event: 'change'
  },
  data: () => ({
    showPassword: false,
    showNewPassword: false,
    newPassword: '',
  }),
  mounted() {
    if(this.isNew) {
      this.onGenerateNewPassword();
    }
  },
  watch: {
    show(nextValue) {
      if(nextValue && this.isNew) {
        this.onGenerateNewPassword();
      }
    }
  },
  methods: {
    onGenerateNewPassword() {
      this.showNewPassword = true
      this.newPassword = btoa(String(+new Date)).substr(-8, 6).toLowerCase()
      this.$emit('change', this.newPassword)
      this.$store.commit('users/setPasswordReset', true)
      return this.newPassword
    },
    onCancel() {
      this.newPassword = null
      this.$emit('change', null)
      this.showNewPassword = false
      this.$store.commit('users/setPasswordReset', false)
    },
    onCopyToClipboard() {
      navigator.clipboard.writeText(this.newPassword);
    }
  }
}
</script>

<style scoped>

</style>
